$(document).ready(function(){

    let slides = $('.home-slider__slide');

    let pages = null;

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    let slider = $('[js-slider]');

    let sliderDelay = slider.data('sliderDelay');

  slider.on('init', async function(e, slick) {

        await sleep(300);

        $('[js-slider]').addClass('ready');
        slides.eq(0).addClass('active');
        pages = $('.slick-dots li');
        pages.eq(0).addClass('active');

    }).slick({
        arrows:false,
        pagination:true,
        dots:true,
        speed: 1000,
        fade: true,
        autoplay: true,
        autoplaySpeed: sliderDelay,
        customPaging: function(slick,index) {
            return `<button role="button" class="page"><span class="arc"><span class="arc__left" style="transition-duration: ${sliderDelay/2}ms; transition-delay: ${sliderDelay/2}ms"></span><span class="arc__right" style="transition-duration: ${sliderDelay/2}ms"></span></span>` + (index + 1) + '</button>';
        },

    }).on('beforeChange',  function(event, slick, currentSlide, nextSlide) {

        slides.eq(nextSlide).addClass('active');

        pages.removeClass('active');

        if(pages && pages.eq(nextSlide).length){
            pages.eq(nextSlide).addClass('active');
        }

    }).on('afterChange', function(event, slick, currentSlide) {
        Lazyload.init();
        slides.each(function(i){
            if(i != currentSlide){
                slides.eq(i).removeClass('active');
            }
        });

    })


    /**
     * Hover area¨block
     * *************************************************/

    const imageHover = $("[js-hover-img]");
    const parentDest = $('#dedi-area__img');
    const dest = $('#dedi-area__img__inner');

    let getCurrentPicSrc = function() {
        return dest.css('background-image').replace(/.*\s?url\([\'\"]?/, '').replace(/[\'\"]?\).*/, '');
    }

    const originalPicture = getCurrentPicSrc();
    const restoreTimeout = 500;

    let timeoutLeave = null;

    imageHover.on('mouseover', function(e){
        clearTimeout(timeoutLeave);
        var el = $(this);
        var src = el.data('image');
        if(src){
            replaceImage(src);
        }
    });

    imageHover.on('mouseleave', function(e){
        timeoutLeave = setTimeout(restoreOriginal, restoreTimeout);
    });

    var replaceImage = function(src){

        parentDest.css('background-image', dest.css('background-image'));

        dest.fadeOut(0, function() {
            var img = new Image();
            img.src = src;

            img.onload = function() {
                dest.css('background-image', 'url('+src+')');
                dest.fadeIn(500);
            }
        });
    }

    var restoreOriginal = function(){
        replaceImage(originalPicture);
    }

});
